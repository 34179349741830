<template>
  <div id="order" style="height: 100%;" v-title data-title="我的订单">
    <template>
      <van-tabs v-model="active" @click="changeTab" style="position: fixed;top:0;z-index: 5;width: 100%;">
        <van-tab v-for="(item, index) in tabList" :key="index" title-inactive-color="#5f5f7e">
          <template slot="title">
           <span style="font-size:15px;">{{ item.name }}
             <!--           <span v-if="item.num">({{item.num}})</span>-->
           </span>
          </template>
        </van-tab>
      </van-tabs>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="container" style="margin-bottom:1rem;margin-top:1.3rem;">
          <template v-if="!noData">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="我是有底线的..."
                :offset="130">
              <div class="order_content_list" v-for="(item, index) in contentList" :key="index"
                   @click="jumpDetails(item)">
                <div class="content flex justify-between" style="padding-right: 0;">
                  <div class="flex">
                    <div class="icon flex align-center justify-center"
                         :class="index % 2 == 0 ? 'state-blue' : 'state-red'">
                      <img src="@/assets/img/order-icon3.png" alt="">
                    </div>
                    <div class="msg">
                      <h5 class="line_one">{{ item.suprname }}</h5>
                      <p>{{ item.telephone }}</p>
                    </div>
                  </div>
                  <div class="content-right">
                    <p v-if="item.state == 0" class="red_color">待支付</p>
                    <p v-if="item.state == 1" class="red_color">待处理</p>
                    <p v-if="item.state == 2" class="subTheme_color">已完成</p>
                    <p v-if="item.state == -2" class="subTheme_color">已拒绝</p>
                    <p class="red_color">￥{{ item.suprprice }}</p>
                  </div>
                </div>
                <div class="action flex align-center justify-between" style="margin-left:-2px;" @click.stop>
                  <div class="subTheme_color">订单日期：{{ item.ctime ? $common.getDateYMD(item.ctime, '-') : ''}}</div>
                  <van-button plain round style="color: #999fb3;padding-top:2px;" v-if="item.state == 1" @click="generateQRCode(item)">催单</van-button>
                </div>
              </div>
            </van-list>
          </template>
          <!--缺省页面-->
          <template v-if="noData">
            <div class="defalut-page">
              <img src="@/assets/img/noRanks.png" alt="">
              <div>暂时新的订单</div>
            </div>
          </template>
        </div>
      </van-pull-refresh>
      <!--手机号绑定-->
      <bind v-if="bindShow" :url="url" ref="bind"/>
      <!--手机号绑定图标-->
      <div class="bind-phone-icon" v-if="bindPic">
        <img src="@/assets/img/bind-phone.png" @click="binds" alt="">
        <img src="@/assets/img/bind-phone-close.png" alt="" @click="closeBind">
      </div>
    </template>
<!--    <template>-->
<!--      <div style="height: 80%;font-size:15px;" class="flex align-center justify-center">-->
<!--        <div>请在微信中打开</div>-->
<!--      </div>-->
<!--    </template>-->
    <!--二维码弹出信息-->
    <template>
      <van-overlay :show="qrCodeShow" >
        <div class="flex align-center justify-center qr-code-box">
          <div>
            <img :src="qrCodeImg" alt="">
            <p class="line_tow">添加客服微信 发送学校+姓名+手机号，订单急速办理</p>
            <img src="@/assets/img/bind-phone-close.png" alt="" @click="qrCodeShow = false" style="">
          </div>
        </div>

      </van-overlay>
    </template>
  </div>
</template>

<script>
import Bind from './bind';
import commonApi from "@/api/common";

export default {
  name: 'PageOrder',
  components: {
    Bind
  },
  props: {
    // 接口前缀地址
    url: String,
    // 详情路由地址
    detailsRouterName: String
  },
  data() {
    return {
      openId: "o5In0shTObf0hLWSccRU2Sy2po50",
      active: 0,
      tabList: [
        {name: '全部', num: 5},
        {name: '待处理', num: 5},
        {name: '待支付', num: 5},
        {name: '已完成'},
        {name: '已拒绝'}
      ],
      page: 1,
      loading: true, // 当loading为true时，转圈圈
      finished: true, // 数据是否请求结束，结束会先显示- 没有更多了 -
      noData: false, // 如果没有数据，显示暂无数据
      isLoading: false, // 下拉的加载图案
      // 订单列表
      contentList: [
        {}, {}, {}
      ],
      bindShow: false,
      bindPic: true,
      orderState: "", //判断催单按钮
      valState: "",
      subscribe: "", // 是否关注公众号
      qrCodeShow: false, // 展示二维码
      qrCodeImg: '', // 二维码图片
    };
  },
  mounted() {
  },
  created() {
    let openid = localStorage.getItem("openId");
    // let openid = 'o5In0skOPOw3d-3L12Fj4Io4Cgvw';
    if (openid && openid != 'null') {
      this.getData();
    }
  },
  methods: {
    /* 是否是微信 */
    isWeiXin() {
      return commonApi.isWeiXin();
    },
    /* 切换tab */
    changeTab(val) {
      this.contentList = [];
      if (val == 0) {
        this.valState = null;
      } else if (val == 1) {
        this.valState = 1
      } else if (val == 2) {
        this.valState = 0
      } else if (val == 3){
        this.valState = 2;
      } else if (val == 4){
        this.valState = -2;
      }
      this.onRefresh();
    },
    // // 列表加载
    onLoad () {
      setTimeout(() => {
        this.getData();
        this.loading = true;
      }, 500);
    },
    // // 下拉刷新
    onRefresh() {
      // 重新初始化这些属性
      this.isLoading = false;
      this.contentList = [];
      this.page = 1;
      this.loading = true;
      this.finished = true;
      this.noData = false;
      // 请求信息
      this.getData();
    },
    /* 获取数据 */
    getData() {
      let url = this.url + 'selectOrder';
      this.axios.get(url, {
        params: {
          openId: localStorage.getItem("openId"),
          // openId: 'o5In0skOPOw3d-3L12Fj4Io4Cgvw',
          state: this.valState
        }
      })
          .then(res => {
            if (res.data.code === 0) {
              this.loading = true;
              let list = res.data.data;
              this.contentList = list;
              if (this.contentList.length === 0) {
                this.noData = true;
              } else {
                this.noData = false;
              }
            } else if (res.data.code === 2) { // 绑定微信手机号
              this.bindShow = true;
            } else {
              this.loading = true;
              this.$toast.fail(res.data.msg);
            }
          });
    },
    /* 催单 */
    reminder(row) {
      let url = this.url + `reminder/${row.code}`;
      this.axios.post(url)
          .then(res => {
            if (res.data.code === 0) {
              this.$toast.success('已经为您催单，请耐心等待哦！');
            } else {
              this.$toast.fail(res.data.msg);
            }
          });
    },
    /* 跳转详情 */
    jumpDetails(row) {
      this.$router.push({
        name: this.detailsRouterName, query: {
          ordecode: row.code,
          schname: row.schname,
          netpwd: row.netpwd,
          netaccount: row.netaccount,
          ctime: row.ctime,
          paytime: row.paytime,
          managetime: row.managetime,
          username: row.username,
          suprname: row.suprname,
          telephone: row.telephone,
          suprprice: row.suprprice,
          state: row.state,
          company: row.company
        },
      })
    },
    closeBind() {
      this.bindPic = false
    },
    binds() {
      this.bindShow = true;
      this.$nextTick(() => {
        this.$refs.bind.show = true;
      })
    },
    /* 生成二维码 */
    generateQRCode (row) {
      let url = this.url + `getQrcode/?schcode=${row.schcode}&schroom=${row.schroom}`;
      this.axios.get(url)
          .then(res => {
            if (res.data.code === 0) {
              this.qrCodeShow = true;
              this.qrCodeImg = `${window.serverConfig.baseUrl}file/${res.data.data}`;
              this.reminder(row);
            } else {
              this.$toast.fail(res.data.msg);
            }
          });
    }
  }
};
</script>
<style scoped lang="scss">
.qr-code-box{
  height: 100%;color: #fff;width:70%;margin: 0 auto;
  >div{
    :first-child{width: 70%;display: block;margin: 0 auto;}
    :nth-child(2){font-size:.25rem;width: 80%;margin:.5rem auto;text-align: center;}
    :last-child{display: block;margin: 0 auto;}
  }
}
</style>
<style>
#order .van-tabs--line .van-tabs__wrap {
  background: #fff;
  padding-bottom: 10px;
  height: 1rem;
  line-height: 1rem;
  padding-top: 5px;
}

#order .van-tabs__line {
  width: .4rem;
  background: #fe663d;
  border-radius: 2px;
}

#order .order_content_list .van-button {
  height: .5rem;
  padding: 0 8px;
}
</style>
