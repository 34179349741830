<template>
  <order :url="url" details-router-name="details"/>
</template>

<script>
import Order from './../page/order';
import Api from './api';
export default {
  name: 'OrderVue',
  components: {
    Order
  },
  data () {
    return {
      url: Api.PREFIX
    };
  },
  mounted () {},
  created () {},
  methods: {}
};
</script>
