<template>
  <div id="bind">
    <!--手机号绑定-->
    <van-overlay :show="show" @click="show = false">
      <div class="flex align-center justify-center" style="height:100%" @click.stop>
        <div class="bind_popup" style="position: relative;">
          <img class="bg_img" src="@/assets/img/bind_bg.png" alt="">
          <img src="@/assets/img/bind-phone-close.png" alt="" style="position: absolute;top: 1%;right: 2%;"
               @click="closeBind">
          <div class="bind_content">
            <H5>绑定手机号</H5>
            <p>为了更好的服务您，请绑定手机号</p>
            <van-form validate-first>
              <van-field clickable v-model="form.telephone" @blur="blurField(form.telephone)"
                         style="margin-bottom:.3rem;" placeholder="请输入您的手机号">
                <!--                :rules="[{ pattern, message: '请输入正确内容' }]"-->
                <template slot="left-icon">
                  <img class="left-icon_img" src="@/assets/img/phone.png" alt="">
                </template>
              </van-field>
              <div style="color: red;margin-bottom:8px;margin-left:15px;" v-if="checkTxtPhone">{{ checkTxtPhone }}</div>
              <template>
                <div class="flex align-center justify-between">
                  <van-field clickable style="margin-right:10px;" @input="vcodeBlur(form.smsCode)"
                             v-model="form.smsCode" placeholder="请输入验证码">
                    <template slot="left-icon">
                      <img class="left-icon_img" src="@/assets/img/phone.png" alt="">
                    </template>
                  </van-field>
                  <div>
                    <van-button type="primary" v-if="!getCodeShow" @click="getTCode" style='font-size: 0.27rem; border-radius: 0.125rem;background: rgb(24, 107, 255);
    border-color: rgb(24, 107, 255);min-width: 2.2rem;width: 2.2rem;padding:0 10px;'>获取验证码
                    </van-button>
                    <div class="flex align-center justify-center" style="min-width: 2.2rem;width: 2.2rem;background: #f7f7f7;height: 100%;padding:12px 0px;border-radius: 5px;text-align: center;" v-show="getCodeShow">
                        <van-count-down ref="countDown" millisecond :time="60000" :auto-start="false" format="ss"
                                        @finish="finish"/>
                        <span style="font-size:.30rem;color: #b2b7c5;padding-left:2px;">&nbsp;重新获取</span>
                    </div>
                  </div>
                </div>
              </template>
              <van-button type="primary" class="bind_btn" :loading="loading" loading-type="spinner" loading-text="正在绑定..." @click="bindSub" block color="#186bff">立即绑定</van-button>
            </van-form>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--手机号绑定成功-->
    <van-overlay :show="bindSuccess">
      <div class="flex align-center justify-center" style="height:100%" @click.stop>
        <div class="bind_popup">
          <img class="bg_img" src="@/assets/img/bind_suc_bg.png" alt="">
          <div class="bind_content">
            <H5 style="text-align: center;margin-top:.5rem;margin-bottom: .2rem;">恭喜您，绑定成功</H5>
            <p style="text-align: center;font-size:.32rem;">当前绑定手机号：<span class="blue_color"
                                                                          v-if="form.telephone"> {{ form.telephone.replace(((form.telephone).slice(-8, -3)), "****") }}</span>
            </p>
            <van-button type="primary" class="bind_btn" @click="knowBtn" block color="#186bff">我知道了</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--    图形验证码-->
    <div class="task" v-if="taskShow">
      <div class="imgCode">
        <img class="close" src="@/assets/img/close.png" @click="close">
        <h1><img src="@/assets/img/yzm.png" alt=""><span>请按照图形输入字母或数字</span></h1>
        <div class="imgCodeCon">
          <van-field v-model="imgCode" placeholder="请输入验证码" class="txt"></van-field>
          <img :src="myImgsrc" @click="getTCode">
        </div>
        <van-button  :loading="loadingg" loading-type="spinner" loading-text="加载中..." @click="getCode"
                    style=" height: 50px; width: 100%; border-radius: 5px; color: #fff; background: linear-gradient(to right, #0d4bed, #0293fc);"
        >确定
        </van-button>
        <p class="des">点击图形即可刷新验证码</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageBind',
  components: {},
  props: {
    // 接口前缀地址
    url: String,
  },
  data() {
    return {
      // 绑定手机号
      show: true,
      openId: localStorage.getItem('openId'),
      // 绑定成功
      bindSuccess: false,
      form: {},
      checkTxtPhone: '',
      checkTxtVcode: '',
      vcodeCheck: false, // 验证码验证
      getCodeShow: false,
      pattern: '',
      validator: /1\d{10}/,
      loading: false,
      loadingg: false,
      taskShow: false, // 图形验证码是否显示
      imgCode: '', // 图形验证码输入框
      myImgsrc: '', // 验证码图形
    };
  },
  created() {
  },
  watch: {},
  mounted() {
  },
  methods: {
    /* 手机号验证 */
    blurField(val) {
      this.checkTxtPhone = '';
      if (!val) {
        this.checkTxtPhone = '手机号不能为空'
      }
    },
    /* 提交 */
    submit() {
    },
    /*获取图形验证码*/
    getTCode(){
      if (!this.form.telephone) {
        return this.$toast.fail("手机号不能为空");
      } else {
        let url = `/basics/smsvalidecode/captcha-code/${this.form.telephone}`;
        this.axios({
          baseURL: this.$baseUrl.baseUrl,
          method: 'get',
          url: url,
          responseType: 'blob', // 防止下载内容的出现乱码
        })
            .then((res) => {
              this.taskShow = true
              let blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
              })
              let imageUrl = window.URL.createObjectURL(blob);
              console.log("imageUrl:",imageUrl);
              this.myImgsrc = imageUrl;
            });
      }
    },
    /* 获取验证码 */
    getCode() {
      this.loadingg = true;
      let url = `/basics/smsvalidecode/code/${this.form.telephone}?captchaCode=${this.imgCode}`;
      this.axios.get(url).then((res) => {
        this.loadingg = false;
        if (res.data.code === 0) {
          this.getCodeShow = true;
          this.$refs.countDown.start();
          this.$toast.success('验证码已发送手机，请注意查收！');
          this.taskShow = false
        } else {
          // this.checkTxtPhone = res.data.msg;
          this.$toast.fail(res.data.msg);
        }
      });
    },
    /*关闭图形验证码*/
    close(){
      this.taskShow = false
    },
    /* 倒计时结束 */
    finish() {
      this.getCodeShow = false;
    },
    /* 验证码验证 验证6位数*/
    vcodeBlur(val) {
      this.checkTxtVcode = '';
      if (!val) {
        this.checkTxtVcode = '验证码不能为空';
        return false;
      }
      // if (val.length == '6') {
      //   this.axios.post('/basics/smsvalidecode/code/check', {
      //     code: val,
      //     phone: this.form.telephone
      //   })
      //       .then(res => {
      //         if (res.data.code === 0) {
      //           this.$toast.success('验证码验证通过');
      //           this.vcodeCheck = true;
      //           this.checkTxtPhone = '';
      //         } else {
      //           this.checkTxtVcode = res.data.msg;
      //           this.$toast.fail(res.data.msg);
      //         }
      //       });
      // }
    },
    /* 立即绑定 */
    bindSub() {
      // if (!this.vcodeCheck) {
      //   this.checkTxtPhone = '手机号码未验证，请先通过验证码校验！';
      //   this.loading = false;
      //   return false;
      // }
      if (this.form.telephone == '') {
        return this.$toast.fail("手机号不能为空");
      }
      if (this.form.smsCode == '') {
        return this.$toast.fail('请输入验证码！');
      }
      this.loading = true;
      let url = this.url + `bind`;
      this.axios.post(url, {
        openid: this.openId,
        phone: this.form.telephone,
        smsCode: this.form.smsCode
      })
          .then(res => {
            this.loading = false;
            if (res.data.code === 0) {
              this.show = false;
              this.bindSuccess = true;
            } else {
              this.$toast.fail(res.data.msg);
            }
          }).catch(() => {
        this.loading = false;
      });
    },
    /* 我知道了 */
    knowBtn() {
      this.bindSuccess = false;
    },
    closeBind() {
      this.show = false
    }
  }
};
</script>
<style scoped lang="scss">
.task{ position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.2); z-index: 999;
  .imgCode{ width: 6.3rem; background: #fff; top: 30%; margin: 0 auto; position: relative; border-radius: 15px; padding: .3rem;
    .close{ position: absolute; top: .3rem; right: .3rem; width: .24rem; height: .24rem;}
    h1{ font-size: .32rem; display: flex; align-items: center; color: #5f5f7e;
      img{ width: .28rem; height: .33rem; margin-right: 5px;}
    }

    .imgCodeCon{ display: flex; margin: .4rem 0;
      .txt{ background: #f7f7f7; margin-right: .15rem; border-radius: 5px;}
      img{ width: 88px; height: 44px;}
    }
    .des{ color: #aaa; text-align: center; padding-top: 10px;}
  }
}
.bind_popup {
  background: #fff;
  width: 90%;
  border-radius: 10px;
  overflow: hidden;

  .bg_img {
    width: 100%;
  }

  .bind_content {
    width: 80%;
    margin-left: 10%;
    margin-top: .2rem;
    margin-bottom: 10%;

    H5 {
      font-size: .36rem;
    }

    p {
      color: #b2b7c5;
      font-size: .2rem;
      margin-bottom: .5rem;
    }

    .left-icon_img {
      width: .2rem;
      margin-top: .1rem;
      margin-right: .1rem;
    }
  }

  .bind_btn {
    border-radius: 5px;
    height: .88rem;
    margin-top: .5rem;
    font-size: .30rem;
  }
}
</style>
<style>
#bind .van-overlay {
  z-index: 120 !important;
}

#bind .van-cell {
  background: #f7f7f7;
  border-radius: 5px;
  font-size: .30rem;
}

#pay .van-cell {
  padding: 15px 22px;
}

#pay .van-cell::after {
  border-bottom: #f0f0f2;
}

#pay [class*=van-hairline]::after {
  border-bottom: #f0f0f2;
}

#pay .van-action-sheet__header {
  text-align: left;
  margin-left: 22px;
  font-weight: bold;
}
</style>
